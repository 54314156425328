export function getAppIdAndApiKey(searchClient) {
  var _searchClient$transpo = searchClient.transporter,
    headers = _searchClient$transpo.headers,
    queryParameters = _searchClient$transpo.queryParameters;
  var APP_ID = 'x-algolia-application-id';
  var API_KEY = 'x-algolia-api-key';
  var appId = headers[APP_ID] || queryParameters[APP_ID];
  var apiKey = headers[API_KEY] || queryParameters[API_KEY];
  return {
    appId: appId,
    apiKey: apiKey
  };
}